import React from "react";

const Cross = ({ width = 29, height = 29, fill = "000000" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 29 29" version="1.1">
      <g
        id="in-App"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Interface-Icons"
          transform="translate(-90.000000, -226.000000)"
          fill={fill}
        >
          <g id="modal-close" transform="translate(57.000000, 193.000000)">
            <path
              d="M47.0202927,43.4847588 L56.9180909,33.5869607 C57.7000766,32.804975 58.9660838,32.8031329 59.7516665,33.5887156 L60.4518698,34.2889189 C61.2348248,35.0718738 61.2357704,36.3403489 60.4536248,37.1224946 L50.5558266,47.0202927 L60.4536248,56.9180909 C61.2356105,57.7000766 61.2374525,58.9660838 60.4518698,59.7516665 L59.7516665,60.4518698 C58.9687116,61.2348248 57.7002365,61.2357704 56.9180909,60.4536248 L47.0202927,50.5558266 L37.1224946,60.4536248 C36.3405089,61.2356105 35.0745016,61.2374525 34.2889189,60.4518698 L33.5887156,59.7516665 C32.8057607,58.9687116 32.804815,57.7002365 33.5869607,56.9180909 L43.4847588,47.0202927 L33.5869607,37.1224946 C32.804975,36.3405089 32.8031329,35.0745016 33.5887156,34.2889189 L34.2889189,33.5887156 C35.0718738,32.8057607 36.3403489,32.804815 37.1224946,33.5869607 L47.0202927,43.4847588 Z"
              id="Rectangle-135"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Cross;
