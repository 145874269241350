import { path } from "@ui/utils";

export const onPageStart = (type = "") => {
  if (
    path(["androidData", "app-agent"], window) &&
    path(["androidData", "js-version"], window)
  ) {
    if (
      path(["sessionStorage"], window) &&
      typeof window.sessionStorage != "undefined"
    ) {
      sessionStorage.setItem("mobileApp", "AndroidApp");
      window.localStorage.setItem("DONT_SHOW_COOKIE_BANNER", "true");
    }
    window.MobileApp.onPageStart(type);
  }
  if (
    path(["iosData", "app-agent"], window) &&
    path(["iosData", "js-version"], window)
  ) {
    window.localStorage.setItem("DONT_SHOW_COOKIE_BANNER", "true");
    if (
      path(["sessionStorage"], window) &&
      typeof window.sessionStorage != "undefined"
    ) {
      sessionStorage.setItem("mobileApp", "iPhoneApp");
    }
    if (
      path(["webkit", "messageHandlers"], window) &&
      window.webkit.messageHandlers[type]
    ) {
      window.webkit.messageHandlers[type].postMessage("");
    }
  }
};
export const sendBranchEvent = (eventData, gaStatus) => {
  // const excludedGAprops = [
  //   "inf",
  //   "domain",
  //   "flight_no",
  //   "no_of_stops",
  //   "seat_selected",
  //   "fare_seat",
  //   "meal_selected",
  //   "baggage_selected",
  //   "app_type",
  // ];
  // let gaEvent = {};
  // if (
  //   path(["androidData", "app-agent"], window) &&
  //   path(["androidData", "js-version"], window) &&
  //   parseInt(path(["androidData", "js-version"], window)) >= 2
  // ) {
  //   eventData["platform"] = "Android";
  //   if (!gaStatus) {
  //     eventData["app_type"] = "webview";
  //     if (Object.keys(eventData).length > 25) {
  //       for (const eventD in eventData) {
  //         if (!excludedGAprops.includes(eventD)) {
  //           gaEvent[eventD] = eventData[eventD];
  //         }
  //       }
  //     } else {
  //       gaEvent = eventData;
  //     }
  //   }

  //   // window.MobileApp.onEventDetailsForBranch(JSON.stringify(eventData));
  //   // window.MobileApp.onEventDetailsForGA(JSON.stringify(gaEvent));
  // }
  // if (
  //   path(["iosData", "app-agent"], window) &&
  //   path(["iosData", "js-version"], window) &&
  //   parseInt(path(["iosData", "js-version"], window)) >= 2
  // ) {
  //   eventData["platform"] = "iOS";
  //   if (!gaStatus) {
  //     eventData["app_type"] = "webview";
  //     if (Object.keys(eventData).length > 25) {
  //       for (const eventD in eventData) {
  //         if (!excludedGAprops.includes(eventD)) {
  //           gaEvent[eventD] = eventData[eventD];
  //         }
  //       }
  //     } else {
  //       gaEvent = eventData;
  //     }
  //   }
  //   // window.webkit.messageHandlers["BRANCH_EVENTS"].postMessage(eventData);
  //   // window.webkit.messageHandlers["GA_EVENTS"].postMessage(gaEvent);
  // }
};
