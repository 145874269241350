import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Sticky extends PureComponent {

  renderProp = () => {
    return this.props.renderChild();
  }

  render() {
    const classNames = `Sticky Sticky--${this.props.position}`
    return (
        <div>
        {!(this.props.position === 'quickfilter') ? <div className="Sticky__fake">{this.props.renderChild ? this.renderProp() : this.props.children}</div> : ''}
            <div className={classNames} style={this.props.style ? { ...this.props.style } : {}}>
                {this.props.children}
            </div>
        </div>
    )
  }
}

Sticky.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.node
}

Sticky.defaultProps = {
  position: 'top',
  children: null
}

export default Sticky;
