import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Slide } from "@lib/Animate";
import elementClass from "element-class";
import classNames from "classnames";
import SheetHeader from "./SheetHeader";
import Sticky from "../Sticky";

class Sheet extends PureComponent {
  historyRef = React.createRef(false)
  onCloseWrapper = (e) => {
    // const removePopstateEvent = new Event('REMOVE_POPSTATE_EVENT');
    // const addPopstateEvent = new Event('ADD_POPSTATE_EVENT');
    // window.dispatchEvent(removePopstateEvent);
    window.history.back()
    // this.props.onClose(e)
    // window.dispatchEvent(addPopstateEvent);
  }
  componentWillReceiveProps(nextProps) {
    const canAppendHashUrl = document.readyState === "complete";
    if (!this.props.isOpen && nextProps.isOpen) {
      elementClass(document.body).add("Sheet--open");
      if (this.props.hashUrl && canAppendHashUrl) {
        window.addEventListener("hashchange", this.handleHashChange);
      }
    } else if (this.props.isOpen && !nextProps.isOpen) {
      elementClass(document.body).remove("Sheet--open");
      if (this.props.hashUrl && canAppendHashUrl) {
        window.removeEventListener("hashchange", this.handleHashChange);
      }
    }
  }
  componentDidUpdate() {
    if (this.props.isOpen && !this.historyRef.current) {
      let currentId = 0
      let bottomSheets = document.getElementsByClassName('backbutton_container')
      bottomSheets = [...bottomSheets]
      if (bottomSheets.length > 0) {
          currentId = bottomSheets.length
      }
      window.history.pushState({currentId}, "",  window.location.href);
      this.historyRef.current = true
    }

    if (!this.props.isOpen) {
      this.historyRef.current = false
    }
  }
  componentWillUnmount() {
    elementClass(document.body).remove("Sheet--open");
    window.removeEventListener("hashchange", this.handleHashChange);
  }
  handleHashChange = (e) => {
    let oldHash = e.oldURL.split("#")[1];
    if (oldHash === this.props.hashUrl) {
      this.onCloseWrapper("closeButton");
    }
  };
  render() {
    const sheetClassList = classNames("Sheet", this.props.sheetClassName);
    const SheetHTML = (
      <div className={`${sheetClassList} backbutton_container`}>
        <div className='close__Btn' style={{pointerEvents: 'none'}} onClick={(e) => {
            e.stopPropagation()
            this.props.onClose(e)
        }}></div>
        <Sticky position="top">
          <SheetHeader
            title={this.props.title}
            onClose={this.onCloseWrapper}
            showCloseButton={this.props.showCloseButton}
            secondaryAction={this.props.secondaryAction}
            secondaryActionClick={this.props.secondaryActionClick}
          />
          {this.props.subHeaderComponent}
        </Sticky>
        <div className="Sheet__content">{this.props.children}</div>
      </div>
    );
    if (this.props.enableAnimation) {
      return (
        <Slide
          in={this.props.isOpen}
          timeout={{ enter: 300, exit: 300 }}
          mountOnEnter={true}
          unmountOnExit={true}
          appear={true}
        >
          {SheetHTML}
        </Slide>
      );
    }
    if (this.props.isOpen) {
      return SheetHTML;
    }
    return null;
  }
}

Sheet.propTypes = {
  children: PropTypes.node,
  /**
   * Whether to show the Sheet or not.
   */
  isOpen: PropTypes.bool,
  /**
   * Title of the Sheet.
   */
  title: PropTypes.string,
  /**
   * SubTitle of the Sheet.
   */
  subtitle: PropTypes.string,
  /**
   * Close event of the Sheet.
   */
  onClose: PropTypes.func,
  /**
   * Whether to enable slide up animation for Sheet. Enabled by default.
   */
  enableAnimation: PropTypes.bool,
  /*
   * Whether to show the close button or not. Default true.
   */
  showCloseButton: PropTypes.bool,
  /*
   * Show Reset button
   */
  secondaryAction: PropTypes.string,
  /*
   * Show Reset button Click
   */
  secondaryActionClick: PropTypes.func,
  subHeaderComponent: PropTypes.node,
  hashUrl: PropTypes.string,
};

Sheet.defaultProps = {
  children: null,
  isOpen: false,
  title: "",
  subtitle: "",
  onClose: (event) => {},
  enableAnimation: true,
  showCloseButton: true,
  hashUrl: null,
  secondaryAction: "",
  secondaryActionClick: () => {},
  subHeaderComponent: null,
};

export default Sheet;
