import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Ripple from "@lib/Animate/Ripple/";

class Button extends PureComponent {
  _handleClick = (event) => {
    event.preventDefault();
    if (!this.props.busy && !this.props.disabled) {
      this.props.onClick(event);
    }
  };
  render() {
    const btnClass = classnames(
      "Button",
      this.props.className,
      `Button--${this.props.size}`,
      `Button--${this.props.type}`,
      {
        "is-disabled":
          this.props.disabled === true &&
          !this.props.disabledLight &&
          !this.props.busy,
        "is-disabled-light":
          this.props.disabledLight === true || this.props.busy,
        "is-disabled-new": this.props.disabledNew === true,
      }
    );

    const loader = (
      <div className="flex flex-center">
        <div className="dot-pulse" />
      </div>
    );

    return (
      <Ripple enableRipple={!this.props.disabled && this.props.enableRipple}>
        {(rippleProps, RippleContent) => {
          return (
            <button
              className={btnClass}
              onClick={this._handleClick}
              {...rippleProps}
            >
              {this.props.busy ? loader : this.props.children}
              {RippleContent}
            </button>
          );
        }}
      </Ripple>
    );
  }
}

Button.propTypes = {
  /**
   * Whether the button is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Label for the Button.
   */
  children: PropTypes.node,
  /**
   * Specifies size of the Button.
   */
  size: PropTypes.oneOf(["small", "full", "medium", "large", "inline"]),
  /**
   * Specifies type of the Button.
   */
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "danger"]),
  /**
   * Specifies classnames for the Button.
   */
  className: PropTypes.string,
  /**
   * Event handler for on click event of the Button.
   */
  onClick: PropTypes.func,
  /**
   * Specifies whether to enable the ripple effect for Button.
   */
  enableRipple: PropTypes.bool,
  /**
   * Whether the button is disabled for new designs
   */
  disbaleNew: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  disabledNew: false,
  children: "Button",
  size: "full",
  type: "primary",
  className: "",
  onClick: () => {},
  enableRipple: true,
};

export default Button;
